import _meta from "@turf/meta";
var exports = {};
var coordEach = _meta.coordEach;
/**
 * Takes a set of features, calculates the bbox of all input features, and returns a bounding box.
 *
 * @name bbox
 * @param {FeatureCollection|Feature<any>} geojson input features
 * @returns {Array<number>} bbox extent in [minX, minY, maxX, maxY] order
 * @example
 * var line = turf.lineString([[-74, 40], [-78, 42], [-82, 35]]);
 * var bbox = turf.bbox(line);
 * var bboxPolygon = turf.bboxPolygon(bbox);
 *
 * //addToMap
 * var addToMap = [line, bboxPolygon]
 */

exports = function (geojson) {
  var bbox = [Infinity, Infinity, -Infinity, -Infinity];
  coordEach(geojson, function (coord) {
    if (bbox[0] > coord[0]) bbox[0] = coord[0];
    if (bbox[1] > coord[1]) bbox[1] = coord[1];
    if (bbox[2] < coord[0]) bbox[2] = coord[0];
    if (bbox[3] < coord[1]) bbox[3] = coord[1];
  });
  return bbox;
};

export default exports;